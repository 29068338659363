import * as actionTypes from "./action-types";
import { updateObject } from "../utility";

const INITIAL_STATE = {
  loggedOut: false,
  userId: null,
  parentOrganisationId: null,
  userRoles: null,
  userEmail: null,
  userFirstName: null,
  userLastName: null,
  awsEmailVerified: false,
  userAccess: null,
  error: null,
  loading: true,
  resetPassword: false,
  authRedirectPath: "/",
  awsVerificationBoolean: false,
  passwordResetEmailSent: false,
  passwordResetSuccess: false,
  emailVerifiedSuccess: false,
  resolver: null,
  authLoading: false,
  verificationId: null,
  loginInfo: null,
  siteRedirect: false,
};
const authStart = (state, action) => {
  return updateObject(state, { error: null });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    userId: action.userId,
    parentOrganisationId: action.parentOrganisationId,
    userRoles: action.userRoles,
    userEmail: action.userEmail,
    userFirstName: action.userFirstName,
    userLastName: action.userLastName,
    awsEmailVerified: action.awsEmailVerified,
    userAccess: action.userAccess,
    error: null,
    loading: false,
    authLoading: false,
    loginInfo: null,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    authLoading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    loggedOut: true,
    userId: null,
    parentOrganisationId: null,
    userEmail: null,
    userFirstName: null,
    userLastName: null,
    userAccess: null,
    loading: false,
    authLoading: false,
    loginInfo: null,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const setVerificationEmailStatus = (state, action) => {
  return updateObject(state, { verificationEmailSent: action.emailSent });
};
const setPasswordResetEmailStatus = (state, action) => {
  return updateObject(state, { passwordResetEmailSent: action.emailSent });
};

const authLoading = (state, action) => {
  return updateObject(state, { loading: action.loading });
};

const setAuthLoadingSpinner = (state, action) => {
  return updateObject(state, { authLoading: action.authLoading });
};

const passwordResetSuccess = (state, action) => {
  return updateObject(state, {
    passwordResetSuccess: action.passwordResetSuccess,
    resetPassword: action.resetPassword,
  });
};

const setResetPassword = (state, action) => {
  return updateObject(state, {
    resetPassword: action.resetPassword,
    userEmail: action.userEmail,
    loading: false,
  });
};

const setUserEmail = (state, action) => {
  return updateObject(state, { userEmail: action.userEmail });
};

const setClearError = (state, action) => {
  return updateObject(state, { error: action.error });
};

const setEmailVerified = (state, action) => {
  return updateObject(state, {
    emailVerifiedSuccess: action.emailVerifiedSuccess,
  });
};

const setAwsVerificationBoolean = (state, action) => {
  return updateObject(state, {
    awsVerificationBoolean: action.awsVerificationBoolean,
  });
};

const setAwsVerifiedBoolean = (state, action) => {
  return updateObject(state, { awsEmailVerified: action.awsEmailVerified });
};

const setVerificationId = (state, action) => {
  return updateObject(state, {
    verificationId: action.verificationId,
    resolver: action.resolver,
  });
};

const setLoginInfo = (state, action) => {
  return updateObject(state, { loginInfo: action.loginInfo });
};

const setSiteRedirect = (state, action) => {
  return updateObject(state, { siteRedirect: action.siteRedirect });
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.SET_VERIFICATION_EMAIL_STATUS:
      return setVerificationEmailStatus(state, action);
    case actionTypes.SET_PASSWORD_RESET_EMAIL_STATUS:
      return setPasswordResetEmailStatus(state, action);
    case actionTypes.PASSWORD_RESET_SUCCESS:
      return passwordResetSuccess(state, action);
    case actionTypes.RESET_PASSWORD:
      return setResetPassword(state, action);
    case actionTypes.AUTH_LOADING:
      return authLoading(state, action);
    case actionTypes.AUTH_LOADING_SPINNER:
      return setAuthLoadingSpinner(state, action);
    case actionTypes.SET_USER_EMAIL:
      return setUserEmail(state, action);
    case actionTypes.SET_CLEAR_ERROR:
      return setClearError(state, action);
    case actionTypes.SET_EMAIL_VERIFIED:
      return setEmailVerified(state, action);
    case actionTypes.SET_AWS_VERIFICATION_BOOLEAN:
      return setAwsVerificationBoolean(state, action);
    case actionTypes.SET_AWS_EMAIL_VERIFIED:
      return setAwsVerifiedBoolean(state, action);
    case actionTypes.SET_VERIFICATION_ID:
      return setVerificationId(state, action);
    case actionTypes.SET_LOGIN_INFO:
      return setLoginInfo(state, action);
    case actionTypes.CLEAR_LOGIN_INFO:
      return setLoginInfo(state, action);
    case actionTypes.SET_SITE_REDIRECT:
      return setSiteRedirect(state, action);

    default:
      return state;
  }
};

export default reducer;
