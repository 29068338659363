import React from "react";
import "./maintenance.css";

class Maintenance extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="maintenancePage">
        <h1>Site is temporarily unavailable.</h1>
        <p>
          Scheduled maintenance is currently in progress. Please check back
          soon.
        </p>
        <p>We apologize for any inconvenience.</p>
      </div>
    );
  }
}

export default Maintenance;
