export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const LAYOUT_TYPE = "LAYOUT_TYPE";
export const RESET = "RESET";
export const NAV_BACK_COLOR = "NAV_BACK_COLOR";
export const NAV_BACK_IMAGE = "NAV_BACK_IMAGE";
export const NAV_BRAND_COLOR = "NAV_BRAND_COLOR";
export const HEADER_BACK_COLOR = "HEADER_BACK_COLOR";
export const NAV_ICON_COLOR = "NAV_ICON_COLOR";
export const LAYOUT6_BACKGROUND = "LAYOUT6_BACKGROUND";
export const RTL_LAYOUT = "RTL_LAYOUT";
export const NAV_FIXED_LAYOUT = "NAV_FIXED_LAYOUT";
export const HEADER_FIXED_LAYOUT = "HEADER_FIXED_LAYOUT";
export const BOX_LAYOUT = "BOX_LAYOUT";
export const NAV_DROPDOWN_ICON = "NAV_DROPDOWN_ICON";
export const NAV_LIST_ICON = "NAV_LIST_ICON";
export const NAV_ACTIVE_LIST_COLOR = "NAV_ACTIVE_LIST_COLOR";
export const NAV_LIST_TITLE_COLOR = "NAV_LIST_TITLE_COLOR";
export const NAV_LIST_TITLE_HIDE = "NAV_LIST_TITLE_HIDE";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const CONFIG_BLOCK = "CONFIG_BLOCK";
export const CHANGE_PRE_LAYOUT = "CHANGE_PRE_LAYOUT";
