import React from "react";

const SignIn = React.lazy(() =>
  import("./Components/Authentication/SignIn/SignIn1")
);
const ResetPassword = React.lazy(() =>
  import("./Components/Authentication/ResetPassword/ResetPassword")
);
const AuthHandler = React.lazy(() =>
  import("./Components/Authentication/AuthHandler/AuthHandler")
);
const UpdatePassword = React.lazy(() =>
  import("./Components/Authentication/UpdatePassword/UpdatePassword")
);

const route = [
  {
    path: "/reset-password",
    exact: true,
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/change-initial-password",
    name: "Change Password",
    exact: true,
    component: UpdatePassword,
  },
  {
    path: "/__/auth/action",
    name: "Authentication Action",
    exact: true,
    component: AuthHandler,
  },
  { path: "/", name: "Signin", component: SignIn },
];

export default route;
