export const USERTOKEN_UPDATE = "USERTOKEN_UPDATE";
export const MESSAGES_SET = "MESSAGES_SET";
export const MESSAGES_LIMIT_SET = "MESSAGES_LIMIT_SET";
export const USER_SET = "USER_SET";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_LOADING_SPINNER = "AUTH_LOADING_SPINNER";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const SET_VERIFICATION_EMAIL_STATUS = "SET_VERIFICATION_EMAIL_STATUS";
export const SET_PASSWORD_RESET_EMAIL_STATUS =
  "SET_PASSWORD_RESET_EMAIL_STATUS";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_CLEAR_ERROR = "SET_CLEAR_ERROR";
export const SET_EMAIL_VERIFIED = "SET_EMAIL_VERIFIED";
export const SET_AWS_VERIFICATION_BOOLEAN = "SET_AWS_VERIFICATION_BOOLEAN";
export const SET_AWS_EMAIL_VERIFIED = "SET_AWS_EMAIL_VERIFIED";
export const SET_VERIFICATION_ID = "SET_VERIFICATION_ID";
export const SET_LOGIN_INFO = "SET_LOGIN_INFO";
export const CLEAR_LOGIN_INFO = "CLEAR_LOGIN_INFO";
export const SET_SITE_REDIRECT = "SET_SITE_REDIRECT";
