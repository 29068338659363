import { combineReducers } from "redux";
import sessionReducer from "./session/reducer";
import configReducer from "./reducer";

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  configState: configReducer,
});

export {
  auth,
  logout,
  setAuthRedirectPath,
  authCheckState,
  verificationEmailSent,
  forgotPasswordRequest,
} from "./session/actions";

export default rootReducer;
