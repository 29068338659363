import * as moment from "moment";
const DEMO = {
  BLANK_LINK: "#!",
  HOME_LINK: "/",
  LOGOUT_LINK: "/logout",
  VERSION_LINK: "/version",
};

export default DEMO;

export const APP_NAME = process.env.REACT_APP_NAME;

//Timezone offset for API calls
export const TZ_OFFSET = Math.floor(new Date().getTimezoneOffset() / 60) * -1;

export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";
export const DATE_TIME_FORMAT_NO_SECONDS = "DD-MM-YYYY HH:mm";

export const DATE_FORMAT = "DD-MM-YYYY";

export const NA = "N/A";

export const TARIFF_CLASSES = [
  {
    tariffClassId: 1,
    tariffClassKey: "DOM",
    tariffClassName: "Domestic",
  },
];

export const BOOLEAN_OPTIONS = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const DEFAULT_NUM_DECIMAL_PLACES = 3;

const NUM_DECIMAL_PLACES_UNITS_OF_ENERGY = 3;

export const nextYear = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
);

export function getSelectedBooleanText(booleanValue) {
  if (booleanValue) return "Yes";
  return "No";
}

export function getSelectedBooleanValue(booleanText) {
  if (booleanText === "true" || booleanText === true) return true;
  return false;
}

export function isString(x) {
  return Object.prototype.toString.call(x) === "[object String]";
}

export function convertToSentenceCase(x) {
  let result = x.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function changeDateToStartOfDay(date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
}

export function changeDateToEndOfDay(date) {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
}

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

// will return a date that is `numberOfDaysAhead` days ahead of `date`
export function getNewDateDaysAHeadOfDate(date, numberOfDaysAhead) {
  const d = new Date(date);
  const newDate = new Date();
  newDate.setDate(d.getDate() + numberOfDaysAhead);
  return newDate;
}

export function wattsToKw(watts) {
  return watts / 1000;
}

export function WhTokWh(watts, row, rowIndex, shouldFormat) {
  if (shouldFormat) {
    return (watts / 1000).toFixed(NUM_DECIMAL_PLACES_UNITS_OF_ENERGY);
  }
  return watts / 1000;
}

export function WhTokWhPrice(watts) {
  return watts * 1000;
}

export function joulesToKwH(joules) {
  return joules / 3_600_000;
}

export function joulesToKwHPrice(joules) {
  return joules * 3_600_000;
}

/**
 * @param input  (string or array)
 */
export function replaceWithCommaSpace(input) {
  if (input.length < 1) return NA;
  const str = input.toString();
  return str.replaceAll(",", ", ");
}

export function numArrayFormatter(arr) {
  if (arr.length < 1) return NA;
  const to3DP = arr.map((e) => e.toFixed(DEFAULT_NUM_DECIMAL_PLACES));
  return replaceWithCommaSpace(to3DP);
}

export function numberFormatter(num, row, rowIndex, numDecimalPlaces) {
  return num
    ? num.toFixed(
        numDecimalPlaces ? numDecimalPlaces : DEFAULT_NUM_DECIMAL_PLACES
      )
    : NA;
}

export function percentFormatter(num) {
  return num ? `${num.toFixed(2)}%` : NA;
}

export function dateFormatter(date) {
  if (date === "" || date === null) return NA;
  return moment(date).format(DATE_TIME_FORMAT);
}

export function dateFormatterNoSeconds(date) {
  if (date === "" || date === null) return NA;
  return moment(date).format(DATE_TIME_FORMAT_NO_SECONDS);
}

export function dateFormatterOnlyDate(date) {
  if (date === "" || date === null) return NA;
  return moment(date).format(DATE_FORMAT);
}

export function columnDataFormatter(data) {
  return data ? data : NA;
}

export function booleanFormatter(bool) {
  return bool === true ? "Yes" : "No";
}

export function currencyFormatter(money, currency) {
  if (!money && money !== 0) return NA;

  // in case currency symbol is passed in instead of currency
  if (isACurrencySymbol(currency)) currency = toCurrency(currency);
  if (!isACurrency(currency)) return money;

  return money.toLocaleString("en-UK", {
    style: "currency",
    currency: currency,
  });
}

export function toCurrencySymbol(currency) {
  let currencySymbol;
  if (currency === "GBP") {
    currencySymbol = "£";
  } else if (currency === "EUR") {
    currencySymbol = "€";
  } else {
    console.error("problem determining currency symbol");
    currencySymbol = "";
  }
  return currencySymbol;
}

function toCurrency(currencySymbol) {
  let currency;
  if (currencySymbol === "£") {
    currency = "GBP";
  } else if (currencySymbol === "€") {
    currency = "EUR";
  } else {
    console.error("problem determining currency");
    currency = "";
  }
  return currency;
}

function isACurrency(currency) {
  return currency === "GBP" || currency === "EUR";
}

function isACurrencySymbol(currencySymbol) {
  return currencySymbol === "£" || currencySymbol === "€";
}

export function isBadData(data) {
  return data === null || typeof data === "string";
}
