import { firebaseAuth, firebase } from "../../auth/Firebase/Firebase";
import * as moment from "moment";
import * as actionTypes from "./action-types";
import axios from "../../axios-spring";
import { TZ_OFFSET } from "../constant";

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    dispatch(setAuthLoadingSpinner(true));
    attemptLogin(email, password, dispatch);
  };
};

const attemptLogin = (email, password, dispatch) => {
  firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(function (user) {
      setTimeout(() => {
        // This block is only reached if the user attempts to log in for first time
        getUserByEmailThenValidateUser(email, user, dispatch);
      }, 1000);
    })
    .catch(function (error) {
      // This block is only reached if an error occurs
      handleLoginException(error, dispatch);
    });
};

const getUserByEmailThenValidateUser = (email, user, dispatch) => {
  axios
    .post("/users/getByEmail/" + TZ_OFFSET, email, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((response) => {
      const verificationDetails = {
        body: response.data.response,
        user: user,
        email: email,
        dispatch: dispatch,
      };
      validateUser(verificationDetails);
    })
    .catch((error) => {
      handleGetByEmailException(error, dispatch);
    });
};

const validateUser = (verificationDetails) => {
  const body = verificationDetails.body;
  const user = verificationDetails.user;
  const email = verificationDetails.email;
  const dispatch = verificationDetails.dispatch;

  if (isExistingParisUser(body)) {
    const isEmailVerified = user?.user.emailVerified;

    if (!isEmailVerified) {
      const jsonBody = {
        continueUrl: process.env.REACT_APP_EMAIL_VERIFICATION_CONTINUEURL,
        email: email,
        firstName: body.firstName ? body.firstName : email,
      };
      sendVerificationEmail(jsonBody, dispatch);
    } else {
      dispatch(recordLogin(body));
    }
  }
};

const isExistingParisUser = (str) => str !== "User does not exist";

/** Sends verification email using spring boot API (**user must be authenticated to use this call)  */
const sendVerificationEmail = (jsonBody, dispatch) => {
  axios
    .post("/users/sendVerificationEmail", jsonBody)
    .then((response) => {
      dispatch(verificationEmailSent(true));
      dispatch(logout());
    })
    .catch((error) => {
      console.log(error);
    });
};

const handleGetByEmailException = (error, dispatch) => {
  const errorMessage = "There was an issue logging in. Please try again later.";
  dispatch(authFail(errorMessage));
  console.error(error);
};

const handleLoginException = (error, dispatch) => {
  const isLoginDetailsInvalid =
    error.code === "auth/wrong-password" ||
    error.code === "auth/invalid-email" ||
    error.code === "auth/user-not-found";
  if (isLoginDetailsInvalid) {
    dispatch(authFail("Your login details are incorrect."));
  } else if (tooManyRequests(error)) {
    dispatch(authFail("You have made too many requests. Try again later."));
  } else {
    console.error(error);
  }
};

const tooManyRequests = (error) => error.code === "auth/too-many-requests";


// invoked when MFA code is submitted or password is changed
export const authFinal = (
  verificationCode,
  verificationId,
  resolver,
  method
) => {
  return (dispatch) => {
    dispatch(setAuthLoadingSpinner(true));
    const cred = firebase.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const hasResolver = resolver !== null && resolver !== undefined;
    const multiFactorAssertion =
      firebase.PhoneMultiFactorGenerator.assertion(cred);
    if (hasResolver) {
      handleResolver(resolver, method, multiFactorAssertion, dispatch);
    }

    dispatch(setVerificationId(null, null));
  };
};

const handleResolver = (resolver, method, multiFactorAssertion, dispatch) => {
  resolver
    .resolveSignIn(multiFactorAssertion)
    .then(function (userCredential) {
      let user = userCredential.user;

      user.getIdToken().then((token) => {
        axios.defaults.headers.common = { Authorization: `${token}` };
        const isEmailVerified = user?.emailVerified;
        const hasChangedPassword = method === "Change Password";
        if (!isEmailVerified) {
          sendEmailVerificationUsingFirebase(user, dispatch);
        } else {
          dispatch(authLoading(true));
          axios
            .post("/users/getByEmail/" + TZ_OFFSET, user.email, {
              headers: { "Content-Type": "text/plain" },
            })
            .then((response) => {
              let userBody = response.data.response;
              const isUserDeleted = userBody.isDeleted;
              if (!isExistingParisUser(userBody)) {
                handleUserWhoIsOnlyAnAppUser(user, method, dispatch);
              } else if (hasChangedPassword) {
                handleUserWhoHasUpdatedPassword(userBody, dispatch);
              } else if (isUserDeleted) {
                handleDeletedUser(dispatch);
              } else {
                dispatch(recordLogin(userBody));
              }
            })
            .catch((error) => {
              handleGetByEmailException(error, dispatch);
            });
        }
      });
    })
    .catch((error) => {
      handleFailedMFACheck(error, dispatch);
    });
};

const sendEmailVerificationUsingFirebase = (user, dispatch) => {
  user
    .sendEmailVerification()
    .then(function () {
      dispatch(verificationEmailSent(true));
      dispatch(setAuthLoadingSpinner(false));
      dispatch(logout());
    })
    .catch(function (error) {
      dispatch(setAuthLoadingSpinner(false));
      if (tooManyRequests(error)) {
        dispatch(authFail("You have made too many requests. Try again later."));
      }
    });
};

const handleUserWhoIsOnlyAnAppUser = (user, method, dispatch) => {
  const hasChangedPassword = method === "Change Password";
  axios
    .post("/appUsers/getByEmail/" + TZ_OFFSET, user.email, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((response) => {
      let userBodyApp = response.data.response;
      if (hasChangedPassword) {
        axios
          .put("/users/updateLastResetDate/" + userBodyApp.userId)
          .then((response) => {
            dispatch(logout());
            dispatch(setSiteRedirect(true));
            dispatch(setPasswordResetSuccess());
            dispatch(authLoading(false));
          });
      } else {
        dispatch(authFail("You are not registered to use this site."));
        dispatch(logout());
      }
    })
    .catch((error) => {
      handleGetByEmailException(error, dispatch);
    });
};

const handleUserWhoHasUpdatedPassword = (userBody, dispatch) => {
  axios
    .put("/users/updateLastResetDate/" + userBody.userId)
    .then((response) => {
      dispatch(setPasswordResetSuccess());
      dispatch(authLoading(false));
    });
};

const handleDeletedUser = (dispatch) => {
  dispatch(authFail("You are no longer registered to use this site."));
  dispatch(logout());
};

const handleFailedMFACheck = (error, dispatch) => {
  const invalidCode = error.code === "auth/invalid-verification-code";
  const missingCode = error.code === "auth/missing-verification-code";
  if (invalidCode) {
    dispatch(
      authFail("You entered the wrong verification code. Please try again.")
    );
    dispatch(setAuthLoadingSpinner(false));
  } else if (missingCode) {
    dispatch(authFail("Missing verification code."));
    dispatch(setAuthLoadingSpinner(false));
  }
};

// Invoked the moment user logs in
export const authCheckState = () => {
  return (dispatch) => {
    const checkAuthInterval =
      process.env.REACT_APP_USER_SESSION_CHECKAUTH_INTERVAL;

    firebaseAuth.onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken()
          .then((token) => {
            axios.defaults.headers.common = { Authorization: `${token}` };
            dispatch(checkUserState(user));
            if (window.authStateCheckInterval) {
              window.clearInterval(window.authStateCheckInterval);
              window.authStateCheckInterval = undefined;
            }
            window.authStateCheckInterval = setInterval(() => {
              dispatch(checkUserState(user));
            }, checkAuthInterval * 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        dispatch(authLoading(false));
        dispatch(logout());
      }
    });
  };
};

const checkUserState = (user) => {
  return (dispatch) => {
    axios
      .post("/users/getByEmail/" + TZ_OFFSET, user.email, {
        headers: { "Content-Type": "text/plain" },
      })
      .then((response) => {
        const userBody = response.data.response;
        const deletedParisUser = userBody.isParisUser && userBody.isDeleted;
        const isEmailVerified = user.emailVerified;

        if (!isExistingParisUser(userBody)) {
          dispatch(authFail("You are not registered to use this site."));
          dispatch(logout());
        } else if (deletedParisUser) {
          dispatch(authFail("You are no longer registered to use this site."));
          dispatch(logout());
        } else if (isEmailVerified) {
          dispatch(setUserAuth(userBody));
          dispatch(setAuthLoadingSpinner(false));
        } else if (!isEmailVerified) {
          dispatch(
            authFail(
              "Your email is not verified. Please sign in again and a new verification link will be sent."
            )
          );
          dispatch(logout());
        }
      });
  };
};

export const setUserAuth = (user) => {
  return (dispatch) => {
    const numDaysLastReset = moment().diff(moment(user.lastResetDate), "days");
    const needsToResetPassword = !user.lastResetDate || numDaysLastReset > 90;

    if (needsToResetPassword) {
      dispatch(resetPassword(true, user.email));
    } else {
      setUsersAccess(user, dispatch);
    }
  };
};

const setUsersAccess = (user, dispatch) => {
  const expiresIn = process.env.REACT_APP_USER_SESSION_TIMEOUT;
  axios
    .get("/users/getAccessByUserId/" + user.userId)
    .then((response) => {
      let userAccessBody = response.data.response;

      let userAccess = {};

      userAccessBody.map(
        (x) =>
          (userAccess = { ...userAccess, [x.componentName]: x.accessLevel })
      );

      dispatch(
        authSuccess(
          user.email,
          user.firstName,
          user.lastName,
          user.userId,
          user.awsEmailVerified,
          userAccess,
          user.parentOrganisationId
        )
      );
      dispatch(checkAuthTimeout(expiresIn));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    if (!window.checkAuthTimeout) {
      window.checkAuthTimeout = setTimeout(() => {
        dispatch(logout());
      }, expirationTime * 1000);
    }
  };
};

export const verificationEmailSent = (verificationEmailSent) => {
  return {
    type: actionTypes.SET_VERIFICATION_EMAIL_STATUS,
    emailSent: verificationEmailSent,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (
  userEmail,
  userFirstName,
  userLastName,
  userId,
  awsEmailVerified,
  userAccess,
  parentOrganisationId
) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    userId: userId,
    userEmail: userEmail,
    userFirstName: userFirstName,
    userLastName: userLastName,
    awsEmailVerified: awsEmailVerified,
    userAccess: userAccess,
    parentOrganisationId: parentOrganisationId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const setUserEmail = (userEmail) => {
  return {
    type: actionTypes.SET_USER_EMAIL,
    userEmail: userEmail,
  };
};

export const setEmailVerifiedSuccess = (emailVerifiedSuccess) => {
  return {
    type: actionTypes.SET_EMAIL_VERIFIED,
    emailVerifiedSuccess: emailVerifiedSuccess,
  };
};

export const logout = () => {
  window.clearInterval(window.authStateCheckInterval);
  window.authStateCheckInterval = undefined;
  window.checkAuthTimeout = undefined;
  firebaseAuth.signOut().then(
    function () {},
    function (error) {
      console.log(error);
    }
  );
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authLoading = (isLoading) => {
  return {
    type: actionTypes.AUTH_LOADING,
    loading: isLoading,
  };
};

export const setAuthLoadingSpinner = (isLoading) => {
  return {
    type: actionTypes.AUTH_LOADING_SPINNER,
    authLoading: isLoading,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const forgotPasswordRequest = (email) => {
  let jsonBody = {
    email: email,
    continueUrl: process.env.REACT_APP_EMAIL_VERIFICATION_CONTINUEURL,
  };
  return (dispatch) => {
    axios
      .post("/users/sendResetPasswordEmail", jsonBody)
      .then(function (user) {
        dispatch(passwordResetEmailSent(true));
      })
      .catch(function (error) {
        dispatch(authFail(error));
      });
  };
};

export const passwordResetEmailSent = (passwordResetEmailSent) => {
  return {
    type: actionTypes.SET_PASSWORD_RESET_EMAIL_STATUS,
    emailSent: passwordResetEmailSent,
  };
};

export const updatePasswordFinal = (newPassword, email) => {
  return (dispatch) => {
    dispatch(setAuthLoadingSpinner(true));

    let firebaseUser = firebaseAuth.currentUser;

    firebaseUser
      .updatePassword(newPassword)
      .catch((error) => console.log(error));
    dispatch(authLoading(true));
    dispatch(passwordResetSuccess(email));
  };
};

export const passwordResetSuccess = (email) => {
  return (dispatch) => {
    var queryDict = {};
    location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        queryDict[item.split("=")[0]] = item.split("=")[1];
      });

    const { continueUrl = "" } = queryDict;
    let url = "/users/getUserIdByEmail";

    if (continueUrl.includes("app") || continueUrl.includes("insights")) {
      url = "/appUsers/getUserIdByEmail";
    }

    axios
      .post(url, email, {
        headers: { "Content-Type": "text/plain" },
      })
      .then((response) => {
        const USER_ID = response.data.response;
        axios.put("/users/updateLastResetDate/" + USER_ID).then((response) => {
          dispatch(setPasswordResetSuccess());
          dispatch(authLoading(false));
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setPasswordResetSuccess = () => {
  return {
    type: actionTypes.PASSWORD_RESET_SUCCESS,
    passwordResetSuccess: true,
    resetPassword: false,
  };
};

// invoked when user follows the reset password link and then submits the Change password form
export const resetPasswordRequest = (code, newPassword, email) => {
  return (dispatch) => {
    dispatch(setAuthLoadingSpinner(true));
    firebaseAuth
      .confirmPasswordReset(code, newPassword)
      .then(function () {
        dispatch(passwordResetSuccess(email));
        dispatch(authLoading(false));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(authFail("There has been a problem resetting your password."));
      });
  };
};

// invoked when user changes initial password or password needs updated (expires after 90 days)
export const updatePassword = (
  email,
  oldPassword,
  newPassword
) => {
  return (dispatch) => {
    dispatch(setAuthLoadingSpinner(true));
    const cred = firebase.EmailAuthProvider.credential(email, oldPassword);
    firebaseAuth.currentUser
      .reauthenticateWithCredential(cred)
      .then(() => {
        firebaseAuth.currentUser
          .updatePassword(newPassword)
          .catch((error) => console.log(error));
        dispatch(passwordResetSuccess(email));
      })
      .catch((error) => {
        handleUpdatePasswordException(error, dispatch);
      });
  };
};

const handleUpdatePasswordException = (error, dispatch) => {
  const isWrongPassword = error.code === "auth/wrong-password";
  if (isWrongPassword) {
    dispatch(authFail("Your old password was incorrect."));
  } else {
    console.error(error);
    dispatch(authFail("There was a problem updating your password."));
  }
};

export const confirmPasswordResetLink = (code) => {
  return (dispatch) => {
    firebaseAuth
      .checkActionCode(code)
      .then(function (response) {
        dispatch(setUserEmail(response.data.email));
        dispatch(authLoading(false));
      })
      .catch(function (error) {
        dispatch(authLoading(false));
        dispatch(authFail("This link is no longer valid."));
      });
  };
};

export const verifyEmailRequest = (code) => {
  return (dispatch) => {
    firebaseAuth
      .applyActionCode(code)
      .then((response) => {
        dispatch(setEmailVerifiedSuccess(true));
      })
      .catch(function (error) {
        console.error(error);
        dispatch(authFail("This link is expired or invalid."));
      });
  };
};

export const verifyAwsEmailRequest = (email) => {
  return (dispatch) => {
    axios
      .post("/verifyAwsEmail", email, {
        headers: { "Content-Type": "text/plain" },
      })
      .then((response) => {
        dispatch(setEmailVerifiedSuccess(true));
      })
      .catch(function (error) {
        console.error(error);
        dispatch(authFail("There was a problem verifying your email."));
      });
  };
};

export const recordLogin = (user) => {
  return (dispatch) => {
    return axios.put("/users/updateLastLogin/" + user.userId);
  };
};

export const resetPassword = (boolean, email) => {
  return {
    type: actionTypes.RESET_PASSWORD,
    userEmail: email,
    resetPassword: boolean,
  };
};

export const clearLoginInfo = () => {
  return {
    type: actionTypes.CLEAR_LOGIN_INFO,
    error: null,
  };
};

export const clearError = () => {
  return {
    type: actionTypes.SET_CLEAR_ERROR,
    error: null,
  };
};

export const setLoginInfo = (message) => {
  return {
    type: actionTypes.SET_LOGIN_INFO,
    error: message,
  };
};

export const setVerificationId = (verificationId, resolver) => {
  return {
    type: actionTypes.SET_VERIFICATION_ID,
    verificationId: verificationId,
    resolver: resolver,
  };
};

export const setAwsEmailVerificationBoolean = (boolean) => {
  return {
    type: actionTypes.SET_AWS_VERIFICATION_BOOLEAN,
    awsVerificationBoolean: boolean,
  };
};

export const setAwsEmailVerified = (boolean) => {
  return {
    type: actionTypes.SET_AWS_EMAIL_VERIFIED,
    awsEmailVerified: boolean,
  };
};

export const setSiteRedirect = (boolean) => {
  return {
    type: actionTypes.SET_SITE_REDIRECT,
    siteRedirect: boolean,
  };
};
